import { format } from 'date-fns';

export function convertCamelCaseToTitleCase(camelCaseString: string) {
  return (
    camelCaseString
      .replaceAll(/([a-z])([A-Z])/g, '$1 $2')
      // Capitalize the first letter of the resulting string
      .replace(/^./, (str) => str.toUpperCase())
  );
}

export function _formatDate(
  date: Date | string | number | null,
  formatting: string,
): string {
  if (date == null) {
    return 'N/A';
  }

  if (typeof date === 'string' || typeof date === 'number') {
    return format(new Date(date), formatting);
  }

  return format(date, formatting);
}

export function humanDate(date: Date | string | number | null): string {
  return _formatDate(date, 'dd.MM.yyyy');
}

export function humanDatetime(date: Date | number | string | null): string {
  return _formatDate(date, 'dd.MM.yyyy HH:mm');
}

export function humanDatetimeWithSec(
  date: Date | number | string | null,
): string {
  return _formatDate(date, 'dd.MM.yyyy HH:mm:ss');
}
