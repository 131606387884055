import z, { boolean, literal, number, object, string, union } from 'zod';

import { BAID_MATCHER, BSNID_MATCHER } from '../common/matchers';

export const createSupportTicketSchema = object({
  first_name: string().min(2).max(60),
  last_name: string().min(2).max(60),
  email: string().email().max(60),
  company: string().max(60).optional(),
  short_description: string().max(200),
  description: string().max(4000),
  category: union([literal('0'), literal('1'), literal('2')]),
  subcategory: string().optional(),
  product_open_banking: string().max(60).optional(),
  environment_open_banking: string().max(60).optional(),
  product_service_open_banking: string().max(60).optional(),
});

export type CreateSupportTicketDto = z.infer<typeof createSupportTicketSchema>;

export const createApiAccessTicketVariablesSchema = object({
  user_email_address: string().email(), // email of requesting user
  api_id: string().uuid(),
  provider_ba: string().optional(),
  provider_so: string().optional(),
  x_dbasa_dev_portal_scopes: string(),
  x_dbasa_dev_portal_request_id: string(),
  consumer_ba: string().optional(),
  consumer_so: string().optional(),
  api_classification: union([
    literal('open'),
    literal('internal'),
    literal('confidential'),
    literal('strictly-confidential'),
  ]),
  only_persons_with_specific_roles: boolean().default(false), // only relevant if api classification is strictly-confidential
  data_will_be_encrypted_on_field_level: boolean().default(false), // only relevant if api classification is strictly-confidential
  data_will_be_encrypted_on_table_level: boolean().default(false), // only relevant if api classification is strictly-confidential
  data_will_be_encrypted_in_transit: boolean().default(false), // only relevant if api classification is strictly-confidential
  subscriber_type: union([
    literal('process'),
    literal('data'),
    literal('user_interface'),
  ]),
  subscriber_sub_type: union([
    // only relevant if subscriber type is data, else leave field empty
    literal('process_configuration'),
    literal('foundation_data'),
    literal('configuration_items'),
    literal('events'),
    literal('reporting'),
    literal('sys_log'),
  ]).optional(),
  // only relevant if API exposes PII
  purpose: union([
    literal('Consent'),
    literal('Necessary to fulfill a contract'),
    literal('Necessary to fulfill a legal obligation'),
    literal('Necessary to protect vital interests'),
    literal(
      'Necessary to perform a task in the public interest or exercise official authority',
    ),
    literal(
      'Necessary to pursue legitimate interests - balancing of interests',
    ),
  ]).optional(), // select the purpose you have for receiving the PII data
  subscriber_description: string(), // the long description
});
const serviceNowIdErrorMsg =
  'Provide valid Business application id (BAXXXXX) or Service offering id (BSNXXXXXXX)';
export const createApiAccessTicketVariablesInputRequestSchema = object({
  apiId: string(),
  scopeIds: z.string().array().min(1, 'Please select at least one scope.'),
  serviceNowIdentifier: string()
    .regex(BAID_MATCHER, serviceNowIdErrorMsg)
    .or(string().regex(BSNID_MATCHER, serviceNowIdErrorMsg)),
  serviceNowIdentifierSysId: string().min(3).max(255),
  consumerServiceOffering: string().optional(),
  onlyPersonsWithSpecificRoles: boolean().default(false), // only relevant if api classification is strictly-confidential
  dataWillBeEncryptedOnFieldLevel: boolean().default(false), // only relevant if api classification is strictly-confidential
  dataWillBeEncryptedOnTableLevel: boolean().default(false), // only relevant if api classification is strictly-confidential
  dataWillBeEncryptedInTransit: boolean().default(false), // only relevant if api classification is strictly-confidential
  subscriberType: union(
    [literal('process'), literal('data'), literal('user_interface')],
    { required_error: 'Please select a subscriber type.' },
  ),
  subscriberSubType: union(
    [
      // only relevant if subscriber type is data, else leave field empty
      literal('process_configuration'),
      literal('foundation_data'),
      literal('configuration_items'),
      literal('events'),
      literal('reporting'),
      literal('sys_log'),
    ],
    { required_error: 'Please select a subscriber sub-type.' },
  ).optional(),
  // only relevant if API exposes PII
  purpose: union([
    literal('Consent'),
    literal('Necessary to fulfill a contract'),
    literal('Necessary to fulfill a legal obligation'),
    literal('Necessary to protect vital interests'),
    literal(
      'Necessary to perform a task in the public interest or exercise official authority',
    ),
    literal(
      'Necessary to pursue legitimate interests - balancing of interests',
    ),
  ]).optional(), // select the purpose you have for receiving the PII data
  subscriberDescription: string(), // the long description
  termsOfUseAccepted: z
    .boolean({
      required_error:
        'Terms of use must be accepted to get access to this API.',
    })
    .optional()
    .refine((value): value is boolean | undefined => value !== false),
});

export const createApiAccessTicket = object({
  sysparm_requested_for: string(), //use portal integration sys id
  sysparm_quantity: number().default(1),
  variables: createApiAccessTicketVariablesSchema,
});

export type CreateApiAccessTicketDto = z.infer<typeof createApiAccessTicket>;
export type CreateApiAccessTicketVariablesDto = z.infer<
  typeof createApiAccessTicketVariablesSchema
>;

export type CreateApiAccessTicketVariablesInputRequest = z.infer<
  typeof createApiAccessTicketVariablesInputRequestSchema
>;

export interface ServiceNowBusinessApplicationDto {
  id: string;
  installStatus: string;
  name: string;
  operationalStatus: string;
  shortDescription: string;
  businessApplicationId: string;
  itApplicationOwner: string;
}

export interface ServiceNowServiceOfferingDto {
  id: string;
  number: string;
  installStatus: string;
  name: string;
  operationalStatus: string;
  shortDescription: string;
  managedBy: string;
}

export interface ServiceNowChangeRequestDto {
  number: string;
  description: string;
  startDate: string;
  endDate: string;
  state: string;
}

export interface ServiceNowItApplicationOwner {
  name: string;
  email: string;
}

export interface ServiceNowDIBusinessApplication {
  name: string;
  baId: string;
  itApplicationOwner?: ServiceNowItApplicationOwner;
}

export interface ServiceNowDigitalIntegrationRequestDto {
  success: boolean;
  message: string;
  state: string;
  approvals: {
    state: string;
    userName: string;
    name: string;
    email: string;
  }[];
  providerBusinessApplication?: ServiceNowDIBusinessApplication;
  consumerBusinessApplication?: ServiceNowDIBusinessApplication;
}
