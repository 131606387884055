import { Dropdown, Flex, H1, P, Skeleton, Tabs } from '@dnb/eufemia';
import { TabsData } from '@dnb/eufemia/components/tabs';
import styled from '@emotion/styled';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import HeroPage from '@/components/HeroPage';
import { useBackend } from '@/hooks/useBackend';
import {
  EventEnvironmentResponse,
  EventReleaseResponse,
} from '@/openapi/schema';
import { DropdownOption } from '@/pages/api-documentation/constants/types';

import EventResourceNotFoundError from './EventResourceNotFoundError';
import KafkaConfigView from './KafkaConfigView/KafkaConfigView';
import SchemaView from './SchemaView';

import ApiPageBanner from '@/illustrations/ApiPageBanner.png?url';

const StyledTabs = styled(Tabs)`
  .dnb-tabs__tabs {
    margin-left: 22rem;
  }
`;

export default function EventPage() {
  const navigate = useNavigate();
  const {
    api: slug,
    environment: environmentParam,
    version: versionParam,
    tab: tabParam,
  } = useParams();

  const [selectedEnvironment, setSelectedEnvironment] =
    useState<EventEnvironmentResponse>();
  const [activeTab, setActiveTab] = useState(tabParam);
  const [selectedRelease, setSelectedRelease] = useState<DropdownOption>();
  const [allEnvironments, setAllEnvironments] = useState<DropdownOption[]>();
  const [releaseDropdownOptions, setReleaseDropdownOptions] =
    useState<DropdownOption[]>();

  const { data: event, isValidating: isValidatingSlug } = useBackend(
    `/events/{eventSlug}`,
    slug ? { params: { path: { eventSlug: slug } } } : null,
  );

  const { data: envs, isValidating: isValidatingEnvs } = useBackend(
    `/events/{eventSlug}/environments`,
    slug ? { params: { path: { eventSlug: slug } } } : null,
  );

  const { data: releases, isValidating: isValidatingReleases } = useBackend(
    `/events/{eventSlug}/environments/{environmentSlug}/releases`,
    slug && selectedEnvironment
      ? {
          params: {
            path: {
              eventSlug: slug,
              environmentSlug: selectedEnvironment.slug,
            },
          },
        }
      : null,
  );

  useEffect(() => {
    if (!isValidatingEnvs && envs) {
      const defaultEnvironment = envs.find((env: EventEnvironmentResponse) =>
        environmentParam === '@default'
          ? env.isDefault
          : env.slug === environmentParam,
      );
      const allEnvironments = envs?.map((env) => ({
        content: env.name,
        key: env.slug,
      }));
      setSelectedEnvironment(defaultEnvironment);
      setAllEnvironments(allEnvironments);
    }
  }, [environmentParam, envs, isValidatingEnvs]);

  useEffect(() => {
    if (releases) {
      const options: DropdownOption[] = [];
      releases.sort(
        (a: EventReleaseResponse, b: EventReleaseResponse) =>
          new Date(b.deployedAt || '1970-01-01').getTime() -
          new Date(a.deployedAt || '1970-01-01').getTime(),
      );

      releases.forEach((release: EventReleaseResponse) => {
        options.push({
          content: release.version,
          selected_value: release?.version,
          key: release.version,
        });
      });

      setReleaseDropdownOptions(options);
      setSelectedRelease(
        versionParam === '@latest'
          ? options[0]
          : options.find((option) => option.content === versionParam),
      );
    }
  }, [releases, versionParam]);

  const isLoading =
    isValidatingSlug || isValidatingEnvs || isValidatingReleases;

  //Change this after react 19 upgrade to use the useHistory hook
  const changeUrlAndState = useCallback(
    (data: any, state: 'env' | 'ver' | 'tab') => {
      const urlIndexToChange = state === 'env' ? 3 : state === 'ver' ? 4 : 5;
      const url: string[] = window.location.pathname.toString().split('/');
      let valueToChange;
      if (state === 'env') {
        valueToChange = data.key;
        setSelectedEnvironment(undefined);
        setSelectedRelease(undefined);
        url[4] = '@latest';
      }
      if (state === 'ver') {
        valueToChange = data.selected_value;
      }
      if (state === 'tab') {
        setActiveTab(data);
        valueToChange = data;
      }
      url[urlIndexToChange] = valueToChange;
      const combinedUrl = url.join('/');
      navigate(combinedUrl);
    },
    [navigate],
  );

  const environmentDropdown = useMemo(
    () => (
      <Flex.Horizontal skeleton={isLoading}>
        <Flex.Vertical gap={false} rowGap={false}>
          <H1 size="x-large">{event?.name}</H1>
          {event?.description && (
            <P space={{ bottom: 'small' }} weight="medium">
              {event?.description}
            </P>
          )}
          <P weight="medium">Environment</P>
          <Dropdown
            data={allEnvironments}
            on_change={({ data }) => changeUrlAndState(data, 'env')}
            skeleton={isLoading}
            title="Environments"
            value={allEnvironments?.findIndex(
              (option) => option.key === selectedEnvironment?.slug,
            )}
          />
        </Flex.Vertical>
      </Flex.Horizontal>
    ),
    [
      allEnvironments,
      changeUrlAndState,
      event?.description,
      event?.name,
      isLoading,
      selectedEnvironment?.slug,
    ],
  );

  const releaseDropdown = useMemo(
    () => (
      <Flex.Vertical
        gap={false}
        rowGap={false}
        space={{ left: 'x-small', bottom: 'medium', top: 'medium' }}
      >
        <P>Version</P>
        <Dropdown
          data={releaseDropdownOptions}
          on_change={({ data }) => changeUrlAndState(data, 'ver')}
          title="Releases"
          value={releases?.findIndex(
            (option) => option.version === selectedRelease?.key,
          )}
        />
      </Flex.Vertical>
    ),
    [changeUrlAndState, releaseDropdownOptions, releases, selectedRelease?.key],
  );

  const schemaContent = useMemo(
    () =>
      selectedEnvironment && event && selectedRelease ? (
        <Skeleton show={isLoading}>
          <SchemaView
            environmentSlug={selectedEnvironment.slug}
            eventSlug={event.slug}
            releaseVersion={selectedRelease.key}
            topChild={releaseDropdown}
          />
        </Skeleton>
      ) : (
        <EventResourceNotFoundError type="release" />
      ),
    [selectedEnvironment, selectedRelease, event, isLoading, releaseDropdown],
  );

  const kafkaContent = useMemo(
    () =>
      selectedEnvironment &&
      event && (
        <Skeleton show={isLoading}>
          <KafkaConfigView
            environmentSlug={selectedEnvironment.slug}
            eventSlug={event.slug}
          />
        </Skeleton>
      ),
    [selectedEnvironment, event, isLoading],
  );

  const tabs: TabsData[] = [
    {
      title: 'Schemas',
      key: 'schemas',
    },
    {
      title: 'Kafka config',
      key: 'kafka',
    },
  ];

  return (
    <HeroPage
      heroIllustration={ApiPageBanner}
      heroLeftComponent={environmentDropdown}
      noContainer
      noTopMargin
      title={event?.name ?? 'loading...'}
    >
      <StyledTabs
        content_spacing={false}
        data={tabs}
        id="SchemaSwitcher"
        on_change={({ key }: { key: string }) => changeUrlAndState(key, 'tab')}
        selected_key={activeTab}
      />
      {activeTab === 'schemas' && schemaContent}
      {activeTab === 'kafka' && kafkaContent}
    </HeroPage>
  );
}
