import { Anchor, Card, Flex, Hr, P, Tag } from '@dnb/eufemia';
import { cloud, person_shield } from '@dnb/eufemia/icons';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import ApiFavouriteButton from '@/components/ApiFavouriteButton';
import { EventWithUserDataResponse } from '@/openapi/schema';
import { humanDatetimeWithSec } from '@/utils';

import { textWithinLimit } from './eventCardUtils';

import style from './index.module.css';

interface EventCardProps {
  event: EventWithUserDataResponse;
  onFavoriteChange: (eventId: string, isFavorite: boolean) => void;
}

export default function EventCard({ event, onFavoriteChange }: EventCardProps) {
  const [serviceProviders, setServiceProviders] = useState<string[]>();
  const [limitedDescription, setlimitedDescription] = useState<string>();

  useEffect(() => {
    const providers: string[] = [];
    event.environments?.map(
      (env) =>
        env.kafkaPlatform && providers.push(env.kafkaPlatform.serviceProvider),
    );
    setServiceProviders([...new Set(providers)]);

    event.description &&
      setlimitedDescription(textWithinLimit(event.description));
  }, [event]);

  return (
    <Card className={style['Card']} stack>
      <Flex.Horizontal justify="space-between">
        <Anchor
          element={Link}
          to={`/events/${event.slug}/@default/@latest/schemas`}
        >
          {event.name}
        </Anchor>

        <ApiFavouriteButton
          apiId={event.id}
          bottom="xx-small"
          isFavorite={event.isFavorite}
          onChange={onFavoriteChange}
        />
      </Flex.Horizontal>
      <P size="x-small" slant="italic" top="x-small">
        Created at: {humanDatetimeWithSec(event.createdAt)}, updated at:{' '}
        {humanDatetimeWithSec(event.updatedAt)}
      </P>

      <P
        className={limitedDescription ? undefined : style['no-description']}
        size="small"
        top="small"
      >
        {limitedDescription ?? 'No description'}
      </P>
      <Hr bottom="x-small" className={style['Divider']} top="small" />
      <Flex.Horizontal justify="space-between">
        <Flex.Item>
          Classification: <Tag text={event.classification} />
        </Flex.Item>
        <Flex.Item>
          {serviceProviders?.map((p) => (
            <Tag icon={cloud} key={p} left="small" text={p} />
          ))}
          {event.piiExposed && (
            <Tag icon={person_shield} left="small" text="PII" />
          )}
        </Flex.Item>
      </Flex.Horizontal>
    </Card>
  );
}
