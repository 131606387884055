const EVENT_DESCRIPTION_LIMIT = 200;

export const textWithinLimit = (
  text: string,
  limit: number = EVENT_DESCRIPTION_LIMIT,
) => {
  if (text.length < limit) return text;

  return `${text.slice(0, Math.max(0, limit))}...`;
};
