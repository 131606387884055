import { EventSchemaResponse } from '@/openapi/schema';
import { toJsonMarkdown } from '@/utils/markdownUtils';
import { capitalizeFirstLetterOfString } from '@/utils/textUtils';

export function GetSchemaMarkdown(schema: EventSchemaResponse): string {
  //Button here does not have an onclick function that is readily available or usable.
  const downloadButton = `:button[Download schema]{url='#download-${schema.type}-schema' variant='tertiary'}`;
  const header = `# ${capitalizeFirstLetterOfString(schema.type)} Schema`;
  const format = `Schema format: \`${schema.format}\``;
  const content = toJsonMarkdown(JSON.stringify(schema.schema, null, '\t'));

  return header + '\n' + format + '\n\n' + downloadButton + '\n' + content;
}
