import { type JSX, useEffect, useMemo, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import Guide from '@/components/Guide';
import { useBackend } from '@/hooks/useBackend';
import useSWRExpect404 from '@/hooks/useSWRExpect404';
import { downloadFile } from '@/utils/downloadUtils';

import EventResourceNotFoundError from '../EventResourceNotFoundError';
import { GetSchemaMarkdown } from './getSchemaMarkdown';

export interface Props {
  environmentSlug: string;
  eventSlug: string;
  releaseVersion: string;
  topChild: JSX.Element;
}

export default function SchemaView({
  environmentSlug,
  eventSlug,
  releaseVersion,
  topChild,
}: Props) {
  const swrExpect404 = useSWRExpect404();
  const navigate = useNavigate();

  const {
    data: schemas,
    isValidating: schemasValidation,
    error,
  } = useBackend(
    `/events/{eventSlug}/environments/{environmentSlug}/releases/{releaseVersion}/schemas`,
    {
      params: {
        path: {
          eventSlug,
          environmentSlug,
          releaseVersion,
        },
      },
    },
    swrExpect404,
  );

  // Generate schema types for download buttons
  const schemaTypes = useMemo(
    () => schemas?.map((schema) => `download-${schema.type}-schema`),
    [schemas],
  );

  // Check URL for schema IDs to download
  const useCheckUrlForSchemaIdsToDownload = (
    ids: string[] | undefined,
    callback: (matchedId: string | undefined) => void,
  ) => {
    const location = useLocation();
    const [matchedId, setMatchedId] = useState<string | undefined>(undefined);
    const hasExecutedRef = useRef(false);

    useEffect(() => {
      if (!ids || !schemas) return;

      const validIds = ids.filter((id): id is string => id !== undefined);
      const foundId = validIds.find((id) => location.hash.includes(id));

      if (foundId) {
        setMatchedId(foundId);
        callback(foundId);
        hasExecutedRef.current = true;
        navigate(location.pathname + location.search, { replace: true });
      }
    }, [location, ids, callback]);

    return matchedId;
  };

  // Download the matched schema file
  useCheckUrlForSchemaIdsToDownload(schemaTypes, (type) => {
    if (type && schemas) {
      const schema = schemas.find(
        (schema) =>
          schema.type === type.replace('download-', '').replace('-schema', ''),
      );
      if (schema) {
        downloadFile(
          `${type.replace('download-', '')}.json`,
          JSON.stringify(schema),
        );
      }
    }
  });

  const markdownGuide = useMemo(
    () =>
      schemas?.length === 0
        ? '# No schema found' +
          '\n' +
          'There was no schema found on the selected version.'
        : schemas?.map(GetSchemaMarkdown).join('\n'),
    [schemas],
  );

  if (error) {
    return <EventResourceNotFoundError type="schema" />;
  }

  return (
    <Guide
      guide={markdownGuide}
      hideSearch
      loading={schemasValidation}
      style={{ paddingTop: '0' }}
      topChild={topChild}
    />
  );
}
